/**
 * @generated SignedSource<<b2c927ee3687a1b78d5fce49bc893db1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ecommerceTracking_itemSearch$data = {
  readonly pageType: string | null;
  readonly searchRequestId: string | null;
  readonly searchTerm: string | null;
  readonly sponsored: {
    readonly metadata: ReadonlyArray<{
      readonly clickTrackerLink: string | null;
      readonly impressionTrackerLink: string | null;
      readonly itemId: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "ecommerceTracking_itemSearch";
};
export type ecommerceTracking_itemSearch$key = {
  readonly " $data"?: ecommerceTracking_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"ecommerceTracking_itemSearch">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ecommerceTracking_itemSearch"
};

(node as any).hash = "c29bd6ebb8b5af62eacd3a080be5cee2";

export default node;
